/* Custom react-dates styles */
.custom-date-picker {
  .DateRangePickerInput,
  .SingleDatePickerInput {
    padding-left: 38px;
  }
}

.DateRangePicker,
.SingleDatePicker,
.DateRangePickerInput,
.SingleDatePickerInput {
  display: block;
}

.DateRangePickerInput__withBorder,
.SingleDatePickerInput__withBorder {
  border-color: #C0C2CC;
}

.DateInput_input {
  padding: 7px;
  font-size: 16px;
  text-align: center;
  border-width: 0;
}

.DateInput {
  width: 100px;
}
